import { toast } from "react-toastify";
import {
  fetchUserProfile,
  loginUser,
  logoutUser,
} from "../../helper/axiosHelper";
import { setUser } from "./userSlice";

export const userLoginAction = (data) => async (dispatch) => {
  //call axios

  const resultPromise = loginUser(data);

  toast.promise(resultPromise, {
    pending: "please wait.....",
  });
  const { status, message, tokens } = await resultPromise;

  if (tokens?.accessJWT) {
    const { accessJWT, refreshJWT } = tokens;

    sessionStorage.setItem("accessJWT", accessJWT);
    localStorage.setItem("refreshJWT", refreshJWT);
    console.log(accessJWT, refreshJWT);
    dispatch(getUserProfile());
    toast[status](message);
  }

};

export const getUserProfile = () => async (dispatch) => {
  //call axios to get user

  const { status, user } = await fetchUserProfile();

  if (status === "success" && user?._id) {
    dispatch(setUser(user));
  }
};

//autologin

export const autoLogin = () => (dispatch) => {
  const accessJWT = sessionStorage.getItem("accessJWT");
  const refreshJWT = localStorage.getItem("refreshJWT");

  if (accessJWT && refreshJWT) {
    return dispatch(getUserProfile());
  }
};

//user Logout
export const userLogoutAction = () => (dispatch) => {
  //remove user from state
  dispatch(setUser({}));
  //call axios
  logoutUser();
  //   and remove everything
  sessionStorage.removeItem("accessJWT");
  localStorage.removeItem("refreshJWT");
};
