import React from "react";
import "./JobColumn.scss";
import { JobList } from "../JobList/JobList";
export const JobColumn = ({ title, column }) => {
  console.log(column);

  column.items.map((el, index) => {
    return console.log(el);
  });

  return (
    <div className="jobboard">
      <header>{title}</header>
      <ul className="ul-list">
        {column.items.map((el, index) => {
          return <JobList key={index} item={el} />;
        })}
      </ul>
    </div>
  );
};
