import React from "react";
import { Col, Row } from "react-bootstrap";
import { BiLogoLinkedin } from "react-icons/bi";
import { AiOutlineYoutube } from "react-icons/ai";
import { FiGithub } from "react-icons/fi";
import "./Footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
    
        <Row className="p-4 gx-0">
          <Col className="d-flex gap-4 p-4">
            <span className="bg-light p-3 rounded-circle border border-dark ">
              <BiLogoLinkedin size={25} color=" rgb(10, 102, 194) " />
            </span>

            <span className="bg-light p-3 rounded-circle  border border-dark">
              <AiOutlineYoutube size={25} color="rgb(205, 32, 31)" />
            </span>
            <span className="bg-light p-3 rounded-circle  border border-dark">
              <FiGithub size={25} color="dark-blue" />
            </span>
          </Col>

          <Col className="d-flex justify-content-end align-items-center gap-5 p-4">
            <h6 className="info">FAQ</h6>
            <h6 className="info">Privacy Policy</h6>
            <h6 className="info">Email Us</h6>
          </Col>
        </Row>
    
    </div>
  );
};
