import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ComingSoon.scss";
import { BsRocketTakeoffFill } from "react-icons/bs";

const ComingSoon = () => {
  return (
    <div className="comingSoon">
      <Container className="">
        <div className="text-center">
          <p className="p-5 fw-bold title">We are working on it......</p>
          <BsRocketTakeoffFill size={50} color="green" />
          <p className="h1 p-5 fw-bold  mb-5">Stay tune.</p>
          <div className="d-flex justify-content-center">
            <Link className="nav-link text-light button" to="/">
              Go back
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ComingSoon;
