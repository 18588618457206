import React, { useEffect, useState } from "react";
import { MainLayout } from "../../layout/mainLayout/MainLayout";
import { Button, Container, Row } from "react-bootstrap";
import "./Login.scss";
import { FcGoogle } from "react-icons/fc";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { googleAxiosWrapper } from "../../helper/axiosHelper";
import { useGoogleLogin } from "@react-oauth/google";
import { autoLogin, userLoginAction } from "../../redux/user/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
const navigate = useNavigate()
const location = useLocation()


const {userInfo} = useSelector((state) => state.userData)

const fromPath = location.state?.from?.pathname || "/dashboard"

useEffect(() => {
  userInfo?._id ? navigate(fromPath) : dispatch(autoLogin())
}, [userInfo?._id, navigate, fromPath, dispatch]);


  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});


  const handleOnLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    const fetchData = async () => {
      if (user.access_token) {
        try {
          const response = await googleAxiosWrapper.getUserInfo(
            user.access_token
          );
          setProfile(response.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [user]);

  console.log(profile);
  // console.log("user:", user)

  useEffect(() => {
    const sendData = async () => {
      if (profile.id) {
        const { name, email, verified_email, picture } = profile;
        const obj = {
          name,
          email,
          verified_email,
          // id,
          picture
        };
        try {
          // const response = await userLoginAction(obj);
          dispatch(userLoginAction(obj));
          // console.log("Data sent successfully:", response);
        } catch (error) {
          console.log(error);
        }
      }
    };
    sendData();
  }, [profile, dispatch, user]);

  return (
    <MainLayout>
      <div className="login">
        <Container className=" text-center d-flex justify-content-center align-items-center">
          <Row className="bg-light fw-bold shadow-lg p-5 col-md-6 rounded">
            <h1 className="p-4 fw-bold">TalenTrova</h1>
            <h4 className="p-3">Sign in with</h4>

            <Row className="gap-3 d-grid fw-bold">
              <Button
                variant="info"
                className="p-3 d-flex justify-content-center gap-3 "
                onClick={() => handleOnLogin()}>
                <FcGoogle size={35} />
                <h4 className="">Google</h4>
              </Button>
              <Button
                variant="secondary"
                className="p-3 d-flex justify-content-center gap-3 ">
                <FaLinkedin size={35} color="" />
                <h4>LinkedIn</h4>
              </Button>
              <Button
                variant="success"
                className="p-3 mb-4 d-flex justify-content-center gap-3 ">
                <FaGithub size={35} />
                <h4>GitHub</h4>
              </Button>
            </Row>
          </Row>
        </Container>
      </div>
    </MainLayout>
  );
};

export default Login;
