import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SearchBar } from "../../components/searchBar/SearchBar";
import "./Landing.scss";
import ai from "../../assets/ai.png";
import { Header } from "../../layout/header/Header";
import { Footer } from "../../layout/footer/Footer";
import { BsRocketTakeoffFill } from "react-icons/bs";

const LandingPage = () => {
  return (
    <>
      <Header />
      <div className="landing">
        <Container className="">
          <p className="h2 fw-bold text-center p-5">
            "Empowering Careers through AI-Driven Connections."
          </p>

          <Row className=" mt-4 d-flex justify-content-between align-items-center gap-5 flex-wrap">
            <Col className="fw-bold p-3">
              <p className="mb-4 text-success h5">
                {" "}
                <BsRocketTakeoffFill /> Coming Soon!
              </p>

              <div className="mt-5 mb-5">
                <p className=" title ">Get Notified When We Launch</p>
                {/* <p className="title ">When we Launch</p> */}
              </div>

              <SearchBar />
            </Col>
            <Col className="p-3">
              <img
                className="myimage"
                src={ai}
                alt="myimage"
                // height="500px"
                width="100%"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
