import { getAllJobAxios } from "../../helper/axiosHelper";
import { setJobData } from "./jobSlice";

export const getAllJobs = () => async (dispatch) => {
  const { status, message, result } = await getAllJobAxios();
  console.log("from action", status);
  if (status === "success") {
    dispatch(setJobData(result));
  }
};
