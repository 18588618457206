import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LandingPage from "./pages/landing/LandingPage";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import ComingSoon from "./pages/comingSoon/ComingSoon";
import { JobBoard } from "./pages/JobDashboard/JobBoard/JobBoard";
import { RequireAuth } from "./components/requireAuth/RequireAuth";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/jdash" element={<JobBoard />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                {" "}
                <Dashboard />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/jobdashboard"
            element={
              <RequireAuth>
                {" "}
                <JobBoard />{" "}
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
};

export default App;
