import axios from "axios";

const apiRoot =
  process.env.REACT_APP_ROOT_API || "http://localhost:8080/api/v1/";
const userAPI = apiRoot + "/user";

//reusuable axiosProcessor
export const axiosProcessor = async ({ method, url, objDt, isPrivate }) => {
  const headers = isPrivate
    ? {
        Authorization: sessionStorage.getItem("accessJWT"),
      }
    : null;
  try {
    const { data } = await axios({
      method,
      url,
      data: objDt,
      headers,
    });
    return data;
  } catch (error) {
    return {
      status: error,
      message: error.message,
    };
  }
};

//registerUser
export const registerUser = (objDt) => {
  const obj = {
    method: "post",
    url: userAPI,
    objDt,
  };
  return axiosProcessor(obj);
};

//login user
export const loginUser = (objDt) => {
  const obj = {
    method: "post",
    url: "http://localhost:8080/api/v1/user/google/login",
    objDt,
  };
  return axiosProcessor(obj);
};

//fetchUserProfile
export const fetchUserProfile = () => {
  const obj = {
    method: "get",
    url: "http://localhost:8080/api/v1/user/google",
    isPrivate: true,
  };
  return axiosProcessor(obj);
};

//logout user
export const logoutUser = () => {
  const obj = {
    method: "patch",
    url: userAPI + "/logout",
    isPrivate: true,
    token: localStorage.getItem("refreshJWT"),
  };
  return axiosProcessor(obj);
};

//google login
export const googleAxiosWrapper = {
  getUserInfo: (accessToken) => {
    return axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      }
    );
  },
};

//jobs
// get the jobs

export const getAllJobAxios = () => {
  const obj = {
    method: "get",
    url: apiRoot + "job/1234",
  };
  return axiosProcessor(obj);
};
