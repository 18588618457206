import React, { useEffect, useState } from "react";
import "./JobBoard.scss";
import { useSelector, useDispatch } from "react-redux";
import { getAllJobs } from "../../../redux/job/jobAction";
import { JobColumn } from "../JobColumn/JobColumn";
import _ from "lodash";
export const JobBoard = () => {
  const dispatch = useDispatch();
  const { jobData } = useSelector((state) => state.job);
  console.log(jobData);
  useEffect(() => {
    dispatch(getAllJobs());
  }, []);

  return (
    <div className="job-board">
      <h1>Job List </h1>
      <div className="job-column">
        {/* {_.map(jobData, (data, id) => {
          console.log(data);
        })} */}
        {Object.keys(jobData).map((category, index) => (
          <div key={index}>
            <JobColumn title={category} column={jobData[category]} />
          </div>
        ))}
      </div>
    </div>
  );
};
