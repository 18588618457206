import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  jobData: {},
  response: {},
};

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    requestPending: (state) => {
      state.isLoading = true;
    },
    setJobData: (state, action) => {
      state.isLoading = false;
      state.jobData = action.payload;
    },
  },
});

const { reducer, actions } = jobSlice;
export const { requestPending, setJobData } = actions;
export default reducer;
