import React from "react";
import "./SearchBar.scss";
export const SearchBar = () => {
  return (
    <div className="search-area">
      <form>
        <input
          className="input-border"
          placeholder="Enter your email address.."
        />
      </form>
      <button className="notify-button" type="submit">
        Notify Me
      </button>
    </div>
  );
};
