import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./redux/user/userSlice";
import jobReducer from "./redux/job/jobSlice";

const store = configureStore({
  reducer: {
    userData: userReducer,
    job: jobReducer,
  },
});

export default store;
