import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Header.scss";
import { Link } from "react-router-dom";
export const Header = () => {
  return (
    <Navbar expand="md" className=" head">
      <Container>
        <Navbar.Brand href="/" className="p-2">
          <span className="header">
            <h1>TalenTrova.ai</h1>
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto h5 gap-4 d-flex align-items-center">
            <Link className="nav-link fw-bold" to="/coming-soon">
              About
            </Link>
            <Link className="nav-link fw-bold" to="/coming-soon">
              Services
            </Link>
            <Link className="nav-link button text-white" to="/coming-soon">
              Sign up
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
