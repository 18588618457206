import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  userInfo: {},
  response: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    requestPending: (state, action) => {
      state.isLoading = true;
    },
    setUser: (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
    },

    logoutUser: (state, action) => {
      state.isLoading = false;
      state.user = {};
      sessionStorage.removeItem("user");
    },

    registerUser: (state, action) => {
      state.isLoading = false;
      state.response = action.payload;
    },
  },
});

const { reducer, actions } = userSlice;

export const { requestPending, setUser, logoutUser, registerUser } = actions;

export default reducer;
