import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const RequireAuth = ({ children }) => {
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.userData);

  if (!userInfo?._id) {
    //redirect to the login page
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  return children;
};
